@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");

body {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-font {
  font-family: "League Spartan", "Inter", sans-serif;
}

:root {
  --font-size-base: 16px;
  --font-size-heading: 24px;
  --font-size-subheading: 18px;
  --font-size-small: 14px;

  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;

  --primary-color: #3bb96e;
  --primary-dark: #2c8b52;
  --primary-darkest: #074924;
  --primary-light: #40d37b;
  --primary-lightest: #e0efe2;

  --secondary-color: #f7ba5d;

  --third-color: #206cff;

  --warring-color-one: #f53f3f;

  --darkest-color: #2f2f2f;
  --lightest-color: #f7f7f7;

  --text-color-zero: #bcbcbc;
  --text-color-one: #4d5056;
  --text-color-two: #818181;
  --text-color-three: #484848;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-list {
  padding: 0 !important;
}

.content {
  margin-top: 70px;
  /* overflow-y: auto; */
  min-height: calc(100vh - 80px);
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* breakepoint changes */
.title-level1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
}

.title-level2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
}

.title-level3 {
  font-size: 18px;
  font-weight: 500;
}

/* md  */
@media (min-width: 768px) {
  .title-level1 {
    font-size: 50px;
    line-height: 50px;
  }

  .title-level2 {
    font-size: 40px;
    line-height: 40px;
  }

  .title-level3 {
    font-size: 20px;
  }
}

/* lg  */
@media (min-width: 1024px) {
  .title-level1 {
    font-size: 58px;
    line-height: 58px;
  }

  .title-level2 {
    font-size: 58px;
    line-height: 58px;
  }
}

.scrollbar-hide {
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* * {
  border: 1px solid red;
} */

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: currentColor !important;
}

.ant-carousel .slick-prev::before {
  color: currentColor !important;
}

.slick-arrow.slick-prev {
  font-size: 20px !important;
}

.ant-carousel .slick-next::before {
  color: currentColor !important;
}

.slick-arrow.slick-next {
  font-size: 20px !important;
}

@media (min-width: 768px) {
  .custom-clear-icon .ant-select-clear {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }
}
